var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-5"},[_c('v-row',{attrs:{"justify":_vm.$vuetify.breakpoint.smAndDown ? 'center' : 'space-between',"justify-xl":"start"}},_vm._l((_vm.products.filter(
        (element) => element.category === _vm.currentCategoryId
      )),function(product){return _c('v-col',{key:product.id,attrs:{"cols":"12","sm":"10","md":"6","lg":"4","xl":"3"}},[_c('v-row',{staticClass:"rounded-lg startup-list-card",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-2"},[(product.image && product.qty_existence === 0)?_c('base-img',{staticClass:"rounded-lg transparent-img",attrs:{"aspect-ratio":1,"src":`${product.image}`,"cover":"","max-width":"6rem"},on:{"click":function($event){_vm.currentProductId = product.id;
              _vm.dialog = !_vm.dialog;}}}):(product.image)?_c('base-img',{staticClass:"rounded-lg",attrs:{"aspect-ratio":1,"src":`${product.image}`,"cover":"","max-width":"6rem"},on:{"click":function($event){_vm.currentProductId = product.id;
              _vm.dialog = !_vm.dialog;}}}):_c('base-img',{staticClass:"rounded-lg",attrs:{"aspect-ratio":1,"src":require('@/assets/no-image.png'),"max-width":"6rem"}})],1),_c('v-col',{staticClass:"pl-5 py-0",attrs:{"cols":"8"}},[_c('v-row',{attrs:{"align-content":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h6',{staticClass:"text-h6 mb-0",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(product.name)+" ")]),(product.qty_existence === 0)?_c('p',{staticClass:"red--text mt-0 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("outOfStock"))+" ")]):_vm._e(),_c('p',{staticClass:"text-body-2 grey--text mb-0"},[_vm._v(" "+_vm._s(product.description ? product.description : "")+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[(product.allergens && product.allergens[0])?_c('v-col',{staticClass:"pb-2 pt-2",attrs:{"cols":"12"}},[_c('v-row',_vm._l((product.allergens),function(allergen){return _c('v-img',{key:allergen,staticClass:"mr-1",attrs:{"aspect-ratio":"1","max-width":"1.3rem","src":require(`@/assets/img/allergens/${allergen}.png`)}})}),1)],1):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('p',{staticClass:"text-justify text-h6 font-weight-normal mb-0"},[_vm._v(" "+_vm._s(_vm.formatPrice(+product.price))+" ")]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"white--text",attrs:{"disabled":product.qty_existence === 0,"block":"","small":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.currentProductId = product.id;
                        _vm.dialog = !_vm.dialog;}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-4"})],1)}),1),(_vm.dialog && _vm.currentProduct)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},on:{"click:outside":function($event){_vm.dialog = !_vm.dialog;
      _vm.clearCurrentProduct();}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"blue-grey darken-4 blue-grey--text text--lighten-4 justify-space-between"},[_c('h3',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t(_vm.getTitleForDialog)))]),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":_vm.handleCloseDialog,"click:outside":_vm.handleCloseDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.showSettingOrder && !_vm.isKiosk)?[_c('SettingOrder',{staticClass:"my-4 pa-3 white",on:{"updateProductPrice":($event) => _vm.alert('emit')}})]:[_c('v-card-text',{staticClass:"text-body-1"},[(_vm.currentProduct)?_c('base-img',{staticClass:"rounded-lg my-3 mx-auto",attrs:{"src":`${
              _vm.currentProduct.image || require('@/assets/no-image.png')
            }`,"aspect-ratio":_vm.currentProduct.image ? 4 / 3 : 1,"max-width":_vm.currentProduct.image ? '320px' : '9rem'}}):_vm._e(),_c('h2',{staticClass:"headline"},[_vm._v(_vm._s(_vm.currentProduct.name))]),_c('h4',{staticClass:"my-3"},[_vm._v(_vm._s(_vm.currentProduct.description))]),(!_vm.isKiosk)?_c('span',[_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.$t("addDetailsProduct")))]),_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-textarea',{staticClass:"pt-2",attrs:{"height":100,"rules":_vm.rulesLetters,"rounded":"","flat":"","solo":"","outlined":""},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}})],1)],1):_vm._e(),(_vm.currentProduct.allergens && _vm.currentProduct.allergens[0])?_c('v-col',{staticClass:"d-flex pb-2 pt-6",attrs:{"cols":"12"}},_vm._l((_vm.currentProduct.allergens),function(allergen){return _c('v-img',{key:allergen,staticClass:"mr-1",attrs:{"aspect-ratio":"1","max-width":"1.4rem","src":require(`@/assets/img/allergens/${allergen}.png`)}})}),1):_vm._e(),_c('v-col',[_c('ListAggregatesCombined')],1)],1),(
            _vm.currentProduct.qty_existence < 10 &&
            _vm.currentProduct.qty_existence > 0 &&
            _vm.currentProduct.qty_existence !== null
          )?_c('p',{staticClass:"red--text mt-0 ml-4"},[_vm._v(" "+_vm._s(_vm.$t("stockQty"))+": "+_vm._s(_vm.currentProduct.qty_existence)+" ")]):_vm._e(),_c('v-card-actions',[_c('v-col',{staticClass:"accent rounded-xl px-3",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-btn',{attrs:{"disabled":_vm.addingProductQuantity == null ||
                  Number(_vm.addingProductQuantity) < 2,"color":"transparent","x-small":"","plain":"","fab":"","elevation":"0"},on:{"click":function($event){_vm.addingProductQuantity--}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-minus")])],1),_c('span',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.addingProductQuantity))]),_c('v-btn',{attrs:{"color":"transparent","x-small":"","fab":"","elevation":"0","disabled":_vm.currentProduct.qty_existence !== null &&
                  _vm.currentProduct.qty_existence === _vm.addingProductQuantity},on:{"click":function($event){_vm.addingProductQuantity++}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-plus")])],1)],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"pa-4 white--text",attrs:{"elevation":"0","small":"","color":"primary","disabled":!_vm.isValid},on:{"click":function($event){return _vm.addToCart()}}},[_vm._v(" "+_vm._s(_vm.$t("add"))+" "+_vm._s(_vm.formatPrice( +(_vm.currentProduct && _vm.currentProduct.price ? _vm.getTotalProductPrice : 0) ))+" ")])],1)]],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }